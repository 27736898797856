import React, { useContext } from "react";
import loginBG from "../assets/videos/login-bg.mp4";
import InputField from "../components/common/forms/InputField";
import Button from "../components/common/buttons/Button";
import Logo from "../assets/images/logo.png";
import { auth } from "../firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { CreateUser } from "../service/restServices";
import CommonContext from "../context/commonContext";
import { redirect, useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const { setUser } = useContext(CommonContext);
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential: any = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log("💡 | file: LoginPage.tsx:18 | user:", user);

        if (user.email) {
          const formData = {
            name: user?.displayName,
            uid: user?.uid,
            email: user?.email,
            photo: user?.photoURL,
          };

          CreateUser(formData).then((response) => {
            console.log("💡 | file: LoginPage.tsx:24 | response:", response);

            if (response?.status) {
              setUser(response?.data?.user);
              localStorage.setItem("auth_token", response?.data?.token);
              navigate("/");
            }
          });
        }
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col justify-center">
      <video autoPlay loop muted className="min-w-full h-screen object-cover">
        <source src={loginBG} type="video/mp4" />
      </video>
      <div className="bg-black absolute w-screen h-screen opacity-90" />
      <div className="absolute z-10 text-white text-center p-4 w-screen h-screen flex flex-col items-center justify-center">
        <div className="w-full max-w-lg">
          <img src={Logo} alt="logo" className="w-24 mx-auto mb-6" />

          <div className="mb-2 w-full">
            <InputField id="email" type="email" placeholder="Email" required />
          </div>
          <div className="mb-4 w-full">
            <InputField
              id="password"
              type="password"
              placeholder="Password"
              required
            />
          </div>

          <Button appearance="filled" status="primary" className="w-full">
            Login
          </Button>
          <Button
            appearance="filled"
            status="error"
            className="w-full bg-red-500 text-white py-2 px-4  mt-2 hover:bg-red-600 "
            onClick={handleGoogleLogin}
          >
            Login with Google
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
