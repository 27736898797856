import cn from "classnames";
import { FC, useContext } from "react";
import Button from "../common/buttons/Button";
import { FaArrowLeft, FaBackward } from "react-icons/fa";
import { CgArrowLeft, CgChevronLeft, CgMenuLeft } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import CommonContext from "../../context/commonContext";

type TProps = {
  onLogout?(): void;
};
export const Navbar: FC<TProps> = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(CommonContext);

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("auth_token");
    navigate("/login");
  };

  return (
    <nav
      className={`h-12 md:h-16 bg-dark-300 shadow flex justify-center w-full`}
    >
      <div className="flex items-center justify-between h-full w-full max-w-5xl px-4">
        {/* Left Bar */}
        <div className="flex gap-1 h-full items-center justify-between w-full">
          <Button
            onClick={handleBack}
            appearance="ghost"
            status="secondary-neutral"
          >
            <CgChevronLeft size={28} className="text-neutral-200" />
          </Button>

          <Button onClick={handleLogout} appearance="ghost" status="primary">
            <Link to="/login">Logout</Link>
          </Button>
        </div>

        {/* Right Bar */}
      </div>
    </nav>
  );
};
