import { useContext } from "react";
import { Tab1 } from "../../components/common/tabs/tab-1";
import { useTabState } from "../../components/common/tabs/useTabState";
import { Layout } from "../../components/layout";
import { SquadPlayerCard } from "../../components/player-card/SquadPlayerCard";
import CommonContext from "../../context/commonContext";

const TABS = ["All", "Batsman", "Bowler", "All-Rounder"];

export const DreamTeamSquadPage = () => {
  const tabState = useTabState(TABS[0]);
  const { dreamPlayers, setDreamPlayers, dreamTeam } =
    useContext(CommonContext);
  console.log(
    "💡 | file: DreamTeamSquadPage.tsx:7 | dreamPlayers:",
    dreamPlayers
  );

  return (
    <Layout>
      <div>
        <div className="flex w-full mb-4 px-2">
          <Tab1 {...tabState} tabs={TABS} />
        </div>
        <div className="grid grid-cols-4 md:grid-cols-6">
          {dreamPlayers
            ?.filter(
              (player: any) =>
                tabState?.activeTab === "All" ||
                player?.playerInfo?.role === tabState?.activeTab
            )
            ?.map((player: any) => (
              <SquadPlayerCard
                playerInfo={player?.playerInfo}
                id={player?._id}
                key={player?._id}
                team={dreamTeam}
              />
            ))}
        </div>
      </div>
    </Layout>
  );
};
