import { useState } from "react";
import { CardBase } from "../common/cards/CardBase";
import { TeamLogo } from "../common/data-display/TeamLogo";
import Typography from "../common/data-display/Typography";
import { ScoreboardOpponentPlayerCard } from "../player-card/ScoreboardOpponentPlayerCard";
import { ScoreboardPlayerCard } from "../player-card/ScoreboardPlayerCard";
import { SelectBatsmanModal } from "./SelectBatsmanModal";
import { SelectBowlerModal } from "./SelectBowlerModal";

const OTHER_TEAM: any = {
  teamA: "teamB",
  teamB: "teamA",
};

const getShortName = (name: string) => {
  // if (name.length > 12) {
  return name.split(" ")[0];
  // }
  return name;
};

export const LiveScoreboard = ({
  matchData,
  liveData,
  currentTeam,
  oponentTeam,
}: any) => {
  const [openSetBatsmanModal, setOpenSetBatsmanModal] = useState(false);
  const [selectBatsmanType, setSelectBatsmanType] = useState("");
  const [openBowlerModal, setOpenBowlerModal] = useState(false);

  const toggleSetBatsmanModal = (type: string) => {
    setOpenSetBatsmanModal(!openSetBatsmanModal);
    setSelectBatsmanType(type);
  };

  const getTopRunScorer = (team: any) => {
    const _playerBatting = matchData?.innings[
      liveData[team]?.inning
    ]?.battingOrder?.sort((a: any, b: any) => (a.runs < b.runs ? 1 : -1));

    return _playerBatting.map((player: any) => {
      return {
        ...player,
        ...matchData?.squad[team]?.playingXI?.find(
          (_p: any) => _p?._id === player?.id
        ),
      };
    });
  };

  const getTopWicketTaker = (team: any) => {
    const _playerBowling = matchData?.innings[
      liveData[team]?.inning
    ]?.bowlingOrder?.sort((a: any, b: any) => (a.wickets < b.wickets ? 1 : -1));

    return _playerBowling.map((player: any) => {
      return {
        ...player,
        ...matchData?.squad[OTHER_TEAM[team]]?.playingXI?.find(
          (_p: any) => _p?._id === player?.id
        ),
      };
    });
  };

  const toggleBowlerModal = () => {
    setOpenBowlerModal(!openBowlerModal);
  };

  const striker = matchData?.squad[currentTeam]?.playingXI?.find(
    (player: any) => player?._id === liveData[currentTeam]?.batsman?.striker?.id
  );

  const nonStriker = matchData?.squad[currentTeam]?.playingXI?.find(
    (player: any) =>
      player?._id === liveData[currentTeam]?.batsman?.nonStriker?.id
  );

  const bowler = matchData?.squad[oponentTeam]?.playingXI?.find(
    (player: any) => player?._id === liveData[currentTeam]?.bowler?.id
  );

  const oponentStriker = matchData?.squad[oponentTeam]?.playingXI?.find(
    (player: any) => player?._id === liveData[oponentTeam]?.batsman?.striker?.id
  );

  const oponentNonStriker = matchData?.squad[oponentTeam]?.playingXI?.find(
    (player: any) =>
      player?._id === liveData[oponentTeam]?.batsman?.nonStriker?.id
  );

  const oponentBowler = matchData?.squad[currentTeam]?.playingXI?.find(
    (player: any) => player?._id === liveData[oponentTeam]?.bowler?.id
  );

  return (
    <CardBase className="shadow shadow-gray-600">
      <div className="flex gap-2 justify-between items-center">
        <div className="w-12 h-12">
          <TeamLogo
            className="w-12 h-12"
            logo={matchData?.teams?.teamA?.theme?.logo}
            size={50}
          />
        </div>
        <div className="w-1/3 text-left">
          <div className="flex justify-left gap-1 items-end">
            <Typography variant="h" size="md" className="leading-none">
              {matchData?.teams[currentTeam]?.code}
            </Typography>
            <Typography variant="h" size="xl" className="leading-none">
              {liveData[currentTeam]?.runs}/{liveData[currentTeam]?.wickets}
            </Typography>
          </div>
          <div className="flex justify-left gap-1 items-end">
            <Typography variant="h" size="sm" className="leading-none">
              Overs
            </Typography>
            <Typography variant="h" size="sm" className="leading-none">
              {liveData[currentTeam]?.overs}.{liveData[currentTeam]?.balls}
            </Typography>
          </div>
        </div>
        <ScoreboardPlayerCard
          team={matchData?.teams[currentTeam]}
          playerInfo={striker?.playerInfo}
          stats={liveData[currentTeam]?.batsman?.striker}
          className="h-12 w-20"
          type="batting"
          isActive
          onClick={() => toggleSetBatsmanModal("striker")}
          role="Striker"
        />
        <ScoreboardPlayerCard
          team={matchData?.teams[currentTeam]}
          playerInfo={nonStriker?.playerInfo}
          stats={liveData[currentTeam]?.batsman?.nonStriker}
          className="h-12 w-20"
          type="batting"
          role="Non-Striker"
          onClick={() => toggleSetBatsmanModal("nonStriker")}
        />
      </div>
      <div className="flex gap-2 mt-4">
        <div className="flex gap-2 w-full">
          {/* Batters */}

          <ScoreboardPlayerCard
            team={matchData?.teams[oponentTeam]}
            playerInfo={bowler?.playerInfo}
            stats={liveData[currentTeam]?.bowler}
            thisOver={liveData[currentTeam]?.thisOver}
            className="w-full h-8"
            type="bowling"
            role="Bowler"
            isOpponent
          />
        </div>
      </div>

      <div className="h-3 w-full border-t border-dark-400 mt-3" />

      <div className="space-y-2 w-full">
        {/* Batters */}
        <div className="flex gap-1 items-center">
          <div className="w-1/3 text-left flex justify-left items-center relative gap-3">
            <TeamLogo
              className="w-8 h-8"
              logo={matchData?.teams?.teamB?.theme?.logo}
              size={20}
            />
            <div className="">
              <div className="flex justify-left gap-1 items-end">
                <Typography variant="h" size="xs" className="leading-none">
                  {matchData?.teams[oponentTeam]?.code}
                </Typography>
                <Typography variant="h" size="md" className="leading-none">
                  {liveData[oponentTeam]?.runs}/{liveData[oponentTeam]?.wickets}
                </Typography>
              </div>
              <div className="flex justify-left gap-1 items-end">
                <Typography variant="h" size="xs" className="leading-none">
                  Overs
                </Typography>
                <Typography variant="h" size="xs" className="leading-none">
                  {liveData[oponentTeam]?.overs}.{liveData[oponentTeam]?.balls}
                </Typography>
              </div>
            </div>
          </div>
          {liveData[oponentTeam]?.status !== "completed" ? (
            <div className="flex w-2/3 rounded-full border">
              <ScoreboardOpponentPlayerCard
                team={matchData?.teams[oponentTeam]}
                playerInfo={oponentStriker?.playerInfo}
                stats={liveData[oponentTeam]?.batsman?.striker}
                type="batting"
                isActive
                onClick={() => toggleSetBatsmanModal("striker")}
                role="Striker"
                isOpponent
              />
              <ScoreboardOpponentPlayerCard
                team={matchData?.teams[oponentTeam]}
                playerInfo={oponentNonStriker?.playerInfo}
                stats={liveData[oponentTeam]?.batsman?.nonStriker}
                type="batting"
                role="Non-Striker"
                onClick={() => toggleSetBatsmanModal("nonStriker")}
                isOpponent
              />
            </div>
          ) : (
            <div className="flex gap-6 flex-1 justify-end w-3/5">
              <div className="flex flex-col">
                {getTopRunScorer(oponentTeam)
                  ?.slice(0, 2)
                  ?.map((player: any) => (
                    <Typography
                      variant="h"
                      key={player?.id}
                      size="xs"
                      className="leading-none text-gray-400"
                    >
                      {getShortName(player?.playerInfo?.name)} - {player?.runs}(
                      {player?.balls})
                    </Typography>
                  ))}
              </div>
              <div className="flex flex-col">
                {getTopWicketTaker(oponentTeam)
                  ?.slice(0, 2)
                  ?.map((player: any) => (
                    <Typography
                      variant="h"
                      key={player?.id}
                      size="xs"
                      className="leading-none text-gray-400"
                    >
                      {getShortName(player?.playerInfo?.name)} -{" "}
                      {player?.wickets}({player?.runs})
                    </Typography>
                  ))}
              </div>

              {/* <Typography
                variant="h"
                size="xs"
                className="leading-none text-gray-400"
              >
                {getTopWicketTaker(oponentTeam)?.playerInfo?.name}{" "}
                {getTopWicketTaker(oponentTeam)?.wickets}-
                {getTopWicketTaker(oponentTeam)?.runs}
              </Typography> */}
            </div>
          )}
        </div>
        {liveData[oponentTeam]?.status !== "completed" ? (
          <div className="flex w-full gap-2 justify-end divide-x">
            <div className="w-1/3 rounded-full border">
              <ScoreboardOpponentPlayerCard
                team={matchData?.teams[currentTeam]}
                playerInfo={oponentBowler?.playerInfo}
                stats={liveData[oponentTeam]?.bowler}
                type="bowling"
                role="Bowler"
                onClick={toggleBowlerModal}
              />
            </div>
            <div className="flex-1 flex items-center gap-1 pl-2">
              {liveData[oponentTeam]?.thisOver?.map(
                (ball: any, ind: number) => (
                  <div
                    key={ind}
                    className="rounded-full w-4 border-1 border-primary-700 h-4 bg-primary-100 text-primary-700 font-semibold flex items-center justify-center text-xs"
                  >
                    {ball.run}
                  </div>
                )
              )}
            </div>
          </div>
        ) : null}
      </div>
      {openSetBatsmanModal && (
        <SelectBatsmanModal
          open={openSetBatsmanModal}
          handleClose={toggleSetBatsmanModal}
          team={matchData?.teams[currentTeam]}
          currentTeam={currentTeam}
          currentSquad={matchData?.squad[currentTeam]?.playingXI}
          selectBatsmanType={selectBatsmanType}
          liveData={liveData}
          matchData={matchData}
        />
      )}
      {openBowlerModal && (
        <SelectBowlerModal
          open={openBowlerModal}
          handleClose={toggleBowlerModal}
          team={matchData?.teams[currentTeam]}
          currentTeam={currentTeam}
          currentSquad={matchData?.squad[currentTeam]?.playingXI}
          matchData={matchData}
          currentInning={liveData[currentTeam]?.inning}
          liveData={liveData}
        />
      )}
    </CardBase>
  );
};
