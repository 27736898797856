export const getPlayerRating = (playerInfo: any) => {
  if (playerInfo.role?.toLowerCase() === "batsman") {
    return playerInfo?.battingLevel + playerInfo?.bowlingLevel / 10;
  } else if (playerInfo.role?.toLowerCase() === "bowler") {
    return playerInfo?.bowlingLevel + playerInfo?.battingLevel / 10;
  } else if (playerInfo.role?.toLowerCase() === "all-rounder") {
    return (
      (playerInfo?.battingLevel + playerInfo?.bowlingLevel) / 2 +
      (playerInfo?.battingLevel + playerInfo?.bowlingLevel) / 50
    );
  } else {
    return playerInfo?.battingLevel;
  }
};

export const sortPlayersByRating = (players: any) => {
  return players.sort((a: any, b: any) => {
    if (getPlayerRating(a?.playerInfo) > getPlayerRating(b?.playerInfo)) {
      return -1;
    } else if (
      getPlayerRating(a?.playerInfo) < getPlayerRating(b?.playerInfo)
    ) {
      return 1;
    } else {
      return 0;
    }
  });
};
