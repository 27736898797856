import { FC, useMemo, useState } from "react";
import { BiMenu } from "react-icons/bi";
import { FaHamburger } from "react-icons/fa";
import Button from "../common/buttons/Button";
import Typography from "../common/data-display/Typography";

type TProps = any;
export const MatchLayout: FC<TProps> = ({ children, matchData }: any) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <div>
      <nav
        className={`h-12 md:h-16 bg-dark-300 shadow flex justify-center w-full`}
      >
        <div className="flex items-center justify-between h-full w-full max-w-5xl px-4">
          {/* Left Bar */}
          <div className="flex gap-1 h-full items-center justify-between w-full">
            <Typography className="text-neutral-200" variant="h">
              {matchData?.title}
            </Typography>
            <Button
              onClick={toggleMenu}
              appearance="ghost"
              status="secondary-neutral"
              className="px-0"
            >
              <BiMenu size={28} className="text-neutral-200" />
            </Button>
          </div>

          {/* Right Bar */}
        </div>
      </nav>
      <div className="bg-dark-100 w-full justify-center flex">
        <div className="w-full max-w-5xl flex flex-col md:flex-row gap-4 px-4 relative overflow-hidden">
          <main className="h-[calc(100vh-3rem)] md:h-[calc(100vh-4rem)]  w-full overflow-y-auto py-4 pb-20 md:mb-0">
            <div className="w-full">{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};
