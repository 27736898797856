import { CardBase } from "../common/cards/CardBase";
import { Badge } from "../common/data-display/Badge";
import { TeamLogo } from "../common/data-display/TeamLogo";
import Typography from "../common/data-display/Typography";

export const MatchCardSquare = () => {
  return (
    <CardBase className="px-4 py-6 shadow flex flex-col items-center gap-4 cursor-pointer">
      <Badge status="warning" variant="lg" appearance="filled">
        Live
      </Badge>
      <Typography variant="h" size="lg">
        Match Title
      </Typography>
      <div className="w-full flex justify-around gap-4">
        <div className="flex flex-col items-center gap-4">
          <TeamLogo
            className="w-12 h-12"
            logo="https://seeklogo.com/images/B/bangladesh-cricket-board-logo-8B2283D781-seeklogo.com.png"
            size={28}
          />
          <div className="flex flex-col items-center">
            <Typography variant="p" size="sm">
              Bangladesh
            </Typography>
            <Typography variant="p" size="sm">
              120/1(9.3)
            </Typography>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4">
          <TeamLogo
            className="w-12 h-12"
            logo="https://upload.wikimedia.org/wikipedia/en/thumb/4/47/FC_Barcelona_%28crest%29.svg/1200px-FC_Barcelona_%28crest%29.svg.png"
            size={28}
          />
          <div className="flex flex-col items-center">
            <Typography variant="p" size="sm">
              Barcelona
            </Typography>
            <Typography variant="p" size="sm">
              120/1(9.3)
            </Typography>
          </div>
        </div>
      </div>
    </CardBase>
  );
};
