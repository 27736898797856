import React, { useCallback } from "react";
import { CgSpinner } from "react-icons/cg";

export interface IButtonProps {
  children: any;
  className?: any;
  disabled?: boolean;
  isLoading?: boolean;
  primary?: boolean;
  title?: string;
  id?: string;
  status?: IButtonStatus;
  appearance?: IButtonAppearance;
  onClick?(event: any): void;
  onKeyDown?(event: any): void;
  badge?: any;
}

export type IButtonStatus =
  | "secondary-neutral"
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "success"
  | "error-secondary"
  | "cancel";

export type IButtonAppearance = "filled" | "outline" | "ghost";

const Button: React.FC<IButtonProps> = ({
  status = "primary",
  appearance,
  className,
  disabled,
  children,
  isLoading,
  title,
  id,
  onClick,
  onKeyDown,
  badge,
}) => {
  const appearanceStyle = useCallback(() => {
    switch (appearance) {
      case "outline":
        return outlineStyle();

      case "ghost":
        return ghostStyle();
      default:
        return filledStyle();
    }
  }, [appearance]);

  const filledStyle = useCallback(() => {
    switch (status) {
      case "primary":
        return "bg-primary-500 text-white border-primary-500 border hover:bg-primary-700 disabled:bg-secondary-700 disabled:border-secondary-700";

      case "secondary":
        return "bg-secondary-600 text-primary-500 border border-1 border-secondary-700 hover:bg-secondary-700 disabled:bg-secondary-600 disabled:border-secondary-600  disabled:text-secondary-700";

      case "error":
        return "bg-error-600 text-white border-error-600 border hover:bg-error-700 disabled:bg-error-300 disabled:border-error-300";

      case "error-secondary":
        return "bg-error-200 text-error-600 disabled:bg-error-300 disabled:border-error-300";

      case "secondary-neutral":
        return "bg-[#FFFFFF] text-tmp_neutral-900 border-[#D0D5DD] border disabled:bg-[#FFFFFF] text-[##D0D5DD] disabled:border-[#D0D5DD]";

      case "warning":
        return "bg-[#FEC84B] text-white border-[#FEC84B] border hover:bg-[#FDB022] disabled:bg-[#FEF0C7] disabled:border-[#FEF0C7]";

      case "success":
        return "bg-[#039855] text-white border-[#039855] border hover:bg-[#027A48] disabled:bg-[#D1FADF] disabled:border-[#D1FADF]";

      default:
        return "bg-white text-neutral-900 border-white border hover:bg-tmp_neutral-100 disabled:bg-tmp_neutral-100 disabled:text-[#D0D5DD]";
    }
  }, [status, disabled]);

  const outlineStyle = useCallback(() => {
    switch (status) {
      case "primary":
        return "text-primary-500 border-primary-500 border hover:bg-secondary-700 disabled:bg-white disabled:border-secondary-700 disabled:text-secondary-700";

      case "secondary":
        return "text-primary-500 border-secondary-700 border bg-secondary-600 hover:bg-secondary-700 disabled:bg-secondary-600 disabled:border-secondary-700 disabled:text-secondary-700";

      case "error":
        return "text-error-600 border-error-600 border hover:bg-error-200 disabled:bg-[white] disabled:border-[#FECDCA] disabled:text-[#FECDCA]";

      case "warning":
        return "text-[#FEC84B] border-[#FEC84B] border hover:bg-[#FFFAEB] disabled:bg-[white] disabled:border-[#FEF0C7] disabled:text-[#FEF0C7]";

      case "success":
        return "text-[#039855] border-[#039855] border hover:bg-[#ECFDF3] disabled:bg-[white] disabled:border-[#D1FADF] disabled:text-[#D1FADF]";

      default:
        return "bg-white text-neutral-900 border-neutral-300 border hover:bg-tmp_neutral-100 disabled:bg-white disabled:text-[#D0D5DD]";
    }
  }, [status]);

  const ghostStyle = useCallback(() => {
    switch (status) {
      case "primary":
        return "text-primary-500 border border-transparent hover:text-primary-700 disabled:text-[#FFFFFF]";

      case "secondary":
        return "text-secondary-600 border border-transparent hover:text-secondary-700 disabled:text-[#D0D5DD]";

      case "error":
        return "text-error-600 border border-transparent hover:text-error-700 disabled:text-[#FFFFFF]";

      case "warning":
        return "text-[#FEC84B] border border-transparent hover:text-[#FDB022] disabled:text-[#FEF0C7]";

      case "success":
        return "text-[#039855] border border-transparent hover:text-[#027A48] disabled:text-[#D1FADF]";

      default:
        return "text-neutral-900 border border-transparent hover:text-[#667085] disabled:text-[#D0D5DD]";
    }
  }, [status]);

  const renderSpinner = () => (
    <>
      <CgSpinner size={24} className="inline mr-2 spinner" />
    </>
  );

  return (
    <button
      className={`
        ${className}
        cursor-pointer
        font-semibold 
        py-2 
        px-6 
        rounded-full
        text-base
        whitespace-nowrap
        disabled:cursor-not-allowed
        flex
        items-center
        justify-center
        gap-2
        relative
        leading-none
         ${appearanceStyle()}
      `}
      disabled={disabled}
      id={id}
      onClick={onClick}
      onKeyDown={onKeyDown}
      title={title}
    >
      {isLoading && renderSpinner()}
      {children}
      {badge ? (
        <span className="absolute -top-2 -right-2 bg-zinc-500 text-white rounded-full min-w-[1.5rem] min-h-[1.5rem] text-xs flex justify-center items-center">
          {badge}
        </span>
      ) : null}
    </button>
  );
};

export default Button;
