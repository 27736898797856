import { useRoutes } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import { HomePage } from "./pages/HomePage";
import { DreamTeamPage } from "./pages/DreamTeam/DreamTeamPage";
import { DreamTeamSquadPage } from "./pages/DreamTeam/DreamTeamSquadPage";
import { DreamTeamPlayPage } from "./pages/DreamTeam/DreamTeamPlayPage";
import { DreamTeamQuickPlayPage } from "./pages/DreamTeam/DreamTeamQuickPlayPage";

export const Routes = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/dream-team",
      element: <DreamTeamPage />,
    },
    {
      path: "/dream-team/squad",
      element: <DreamTeamSquadPage />,
    },
    {
      path: "/dream-team/play",
      element: <DreamTeamPlayPage />,
    },
    {
      path: "/dream-team/quick-play/:id",
      element: <DreamTeamQuickPlayPage />,
    },
  ]);
  if (!element) return null;

  return element;
};
