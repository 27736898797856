import cn from "classnames";
import { TBaseTabProps } from "../config";

import { Switch } from "../switch";
import { TPassedProps, TSuffixRecord } from "../switch/types";

type TProps<T extends string> = TBaseTabProps<T> & TPassedProps;
export const Tab1 = <T extends string>({
  linkConfig,
  tabs = [],
  activeTab,
  onTabClick,
  size = "",
  suffixRecord = {} as TSuffixRecord<T>,
  isDisabled,
}: TProps<T>) => {
  return (
    <ul className={cn(`flex flex-wrap gap-1 md:gap-2`)}>
      {tabs.map((title: T) => {
        const isSelected = activeTab === title;
        return (
          <li
            key={title}
            id={title}
            className={isDisabled ? "pointer-events-none opacity-60" : ""}
          >
            <Switch
              title={title}
              classValue={cn(
                "flex w-full sm:w-auto relative bg-tmp_neutral-100  font-semibold text-center cursor-pointer",
                "py-1 px-3 text-sm md:py-2 md:px-4 md:text-base"
              )}
              selectedClassValue={cn("absolute inset-0 bg-primary-500 rounded")}
              textClassValue={cn(
                "relative whitespace-nowrap",
                isSelected ? "text-neutral-100" : "text-neutral-300"
              )}
              size={size}
              isSelected={isSelected}
              linkConfig={linkConfig}
              onClick={onTabClick ? () => onTabClick(title) : null}
              suffixRecord={suffixRecord}
            />
          </li>
        );
      })}
    </ul>
  );
};
