import { FaCrown } from "react-icons/fa";
import { CardBase } from "../../components/common/cards/CardBase";
import { StatsItem } from "../../components/common/data-display/StatsItem";
import Typography from "../../components/common/data-display/Typography";
import illustration1 from "../assets/images/illustration-1.png";
import { Layout } from "../../components/layout";
import { CreateQuickMatch, GetUserDreamTeam } from "../../service/restServices";
import { useContext, useEffect, useState } from "react";
import CommonContext from "../../context/commonContext";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/common/buttons/Button";
import { CreateDreamTeamModal } from "../../components/dream-team/CreateDreamTeamModal";
import { PlayModal } from "../../components/dream-team/PlayModal";

export const DreamTeamPlayPage = () => {
  const { dreamTeam, setDreamTeam, user } = useContext(CommonContext);
  const [loadingQuickMatch, setLoadingQuickMatch] = useState(false);
  const navigate = useNavigate();
  const [openPlayModal, setOpenPlayModal] = useState(false);

  if (!dreamTeam) {
    navigate("/dream-team");
  }

  const togglePlayModal = () => {
    setOpenPlayModal((prev) => !prev);
  };

  return (
    <Layout>
      <div className="flex flex-col gap-4">
        <CardBase
          className={`relative overflow-hidden py-10`}
          // backgroundColor={dreamTeam?.theme?.color}
        >
          <img
            src={dreamTeam?.theme?.logo}
            alt={dreamTeam?.code}
            className="absolute -right-28 -bottom-1/2 opacity-30 object-contain h-[200%]"
          />
          <div className="w-3/4 flex flex-col gap-2">
            <FaCrown />
            <Typography variant="h" size="3xl">
              {dreamTeam?.title}
            </Typography>
            <div className="flex gap-2">
              <StatsItem label="Played" value={dreamTeam?.mathces?.played} />
              <StatsItem label="Won" value={dreamTeam?.mathces?.won} />
              <StatsItem label="Lost" value={dreamTeam?.mathces?.lost} />
            </div>
          </div>
        </CardBase>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <button onClick={togglePlayModal}>
            <CardBase
              //   backgroundColor={dreamTeam?.theme?.color}
              padding={false}
              className="h-48 cursor-pointer overflow-hidden relative"
              // backgroundImage={mash1}
            >
              <img
                src={
                  "https://i.pinimg.com/736x/49/07/39/49073921fd9e8bf44fcd31d17d0aae2f.jpg"
                }
                alt="kala-para-na"
                className="absolute w-full opacity-30 object-cover h-[200%]"
              />
              <div className="w-full h-full  px-3 py-1 flex items-end">
                <Typography variant="h" size="lg" width="bold">
                  Play
                </Typography>
              </div>
            </CardBase>
          </button>

          <CardBase
            //   backgroundColor={dreamTeam?.theme?.color}
            className="h-48 cursor-pointer overflow-hidden relative"
            padding={false}
          >
            <img
              src={
                "https://wallpapers.com/images/hd/bangladesh-cricket-team-green-poster-3532ukxu2ppipg3d.jpg"
              }
              alt="kala-para-na"
              className="absolute w-full opacity-30 object-cover h-[200%]"
            />
            <div className="w-full h-full  px-3 py-1 flex items-end">
              <Typography variant="h" size="lg" width="bold">
                Head to Head
              </Typography>
            </div>
          </CardBase>

          <CardBase
            //   backgroundColor={dreamTeam?.theme?.color}
            className="h-48 cursor-pointer overflow-hidden relative"
            padding={false}
          >
            <img
              src={"https://wallpapercave.com/wp/wp7066810.png"}
              alt="kala-para-na"
              className="absolute w-full opacity-30 object-cover h-[200%] object-center"
            />
            <div className="w-full h-full  px-3 py-1 flex items-end">
              <Typography variant="h" size="lg" width="bold">
                Friendly
              </Typography>
            </div>
          </CardBase>
        </div>
      </div>
      <PlayModal open={openPlayModal} onClose={togglePlayModal} />
    </Layout>
  );
};
