import cn from "classnames";
import { TSwitchProps, TTypeKey } from "./types";
import { Children } from "./Children";
import { Link } from "react-router-dom";
import { useResolveTo } from "../useResolveTo";

export const Switch = <T extends TTypeKey>(props: TSwitchProps<T> & any) => {
  const {
    title,
    icon,
    suffixRecord,
    linkConfig,
    classValue,
    isSelected,
    selectedClassValue,
    textClassValue,
    selectedId,
    children,
    iconsOnly,
    draggingTab,
    tabOptions,
    ...rest
  } = props;

  const childrenProps = {
    selectedId,
    isSelected,
    selectedClassValue,
    textClassValue,
    children,
  };

  const text = (
    <>
      <div
        className={cn(
          "flex items-center text-center gap-2",
          draggingTab ? "opacity-100" : "opacity-100"
        )}
      >
        {icon ? icon : null}
        {iconsOnly ? null : title}
        {title !== "More..." && suffixRecord[title] !== undefined ? (
          <span
            className={`ml-2 ${
              isSelected
                ? "text-primary-500 bg-primary-50 border-[#8AC0F2] "
                : "text-neutral-500 bg-neutral-100 border-[#D0D5DD] "
            }  text-xs  leading-[18px] font-normal border rounded-full pr-2 pl-2 border-1 `}
          >
            {suffixRecord[title] ?? ""}
          </span>
        ) : null}
        {tabOptions}
      </div>
    </>
  );

  const to = useResolveTo(title, linkConfig);

  if (to) {
    return (
      <Link
        id={title}
        to={`${
          to === "?tab=Customer" || to === "?tab=Material"
            ? "/configure/master-data" + to
            : to
        }`}
        className={cn(classValue)}
        {...(rest as any)}
      >
        <Children {...childrenProps}>{text}</Children>
      </Link>
    );
  }
  return (
    <button
      id={title}
      className={cn(classValue, draggingTab ? "opacity-0 cursor-grabbing" : "")}
      {...(rest as any)}
    >
      <Children {...childrenProps}>{text}</Children>
    </button>
  );
};
