import Axios from "axios";

export const config = {
  // serverURL: "http://localhost:5005/",
  serverURL:
    process.env.REACT_APP_SERVER_URL || "https://stw-server.onrender.com/",
  userId: "62ef86ee9c0bd23688233078",
};

export const getApi = async (action: any) => {
  try {
    const apiUrl = `${config.serverURL}${action}`;
    const response = await Axios.get(apiUrl);

    return response?.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

export const postApi = async (action: any, data: any) => {
  try {
    const apiUrl = `${config.serverURL}${action}`;
    const response = await Axios.post(apiUrl, data);

    return response?.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const putApi = async (action: any, data: any) => {
  try {
    const response = await Axios.put(`${config.serverURL}${action}`, data);
    return response?.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

export const postFormData = async (action: any, formData: any) => {
  try {
    const response = await Axios.post(
      `${config.serverURL}${action}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data;
  } catch (error: any) {
    return error.response?.data;
  }
};
