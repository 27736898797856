import io from "socket.io-client";
import { config } from "./httpConfig";

export class SocketManager {
  socket;

  constructor(
    public context: any,
    private events: { onSend: () => void; onReceive: () => void }
  ) {
    this.socket = io(config.serverURL);
    // this.socket = io("http://localhost:5005/");
  }
  private __send(
    socketObj: any,
    endpoint: string,
    data: any,
    onProgress: (arg0: any) => void
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      // if (typeof mainProgress !== 'undefined') mainProgress.state.animating = true;
      const reqId = "r" + Math.random();
      socketObj.emit(endpoint, { reqId: reqId, data: data });
      this.events.onSend();
      const onReceiveTimeout = setTimeout(() => {
        this.events.onReceive();
      }, 10000);
      socketObj.once(endpoint + ":" + reqId + ":reply", (data: any) => {
        resolve(data);
        clearTimeout(onReceiveTimeout);
        this.events.onReceive();
        // if (typeof mainProgress !== 'undefined') mainProgress.state.animating = false;
        //
        socketObj.off(endpoint + ":" + reqId + ":progress");
      });
      socketObj.on(endpoint + ":" + reqId + ":progress", (data: any) => {
        //
        if (onProgress) {
          onProgress(data);
        }
      });
      socketObj.once(endpoint + ":" + reqId + ":error", (data: any) => {
        reject(data);
        // if (typeof mainProgress !== 'undefined') mainProgress.state.animating = false;
        socketObj.off(endpoint + ":" + reqId + ":progress");
      });
    });
  }
  send(endpoint: string, data: any, onProgress?: any) {
    return this.__send(this.socket, endpoint, data, onProgress);
  }
  post(url: string, data: any, noJSON: any) {
    return new Promise((resolve) => {
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function () {
        if (
          xmlHttp.readyState == 4 &&
          (xmlHttp.status == 200 || xmlHttp.status === 301)
        ) {
          resolve(
            noJSON ? xmlHttp.responseText : JSON.parse(xmlHttp.responseText)
          );
        }
      };
      xmlHttp.open("POST", url, true); // true for asynchronous
      xmlHttp.setRequestHeader("Content-type", "application/json");
      xmlHttp.send(JSON.stringify(data));
    });
  }
  get(url: string) {
    return new Promise((resolve) => {
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function () {
        if (
          xmlHttp.readyState == 4 &&
          (xmlHttp.status == 200 || xmlHttp.status === 301)
        ) {
          resolve(xmlHttp.responseText);
        }
      };
      xmlHttp.open("GET", url, true); // true for asynchronous
      xmlHttp.send(null);
    });
  }
}
