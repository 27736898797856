import { CardBase } from "../common/cards/CardBase";
import Typography from "../common/data-display/Typography";

export const SummaryData = ({ inningsData, inning }: any) => {
  return (
    <CardBase className="shadow shadow-gray-600 px-2">
      <div className="flex justify-between bg-dark-500 relative overflow-hidden w-full items-center hover:cursor-pointer rounded">
        <img
          src={inningsData.teamData?.theme?.logo}
          className="w-48 absolute -left-20 opacity-50"
        />
        <div
          className="flex items-center justify-between w-full h-full opacity-50 absolute rounded bg-dark-400"
          //   style={{ backgroundColor: inningsData.teamData?.theme?.color }}
          style={{
            background: `linear-gradient(to right,  #222, ${inningsData.teamData?.theme?.color})`,
          }}
        />
        <div className="z-20 flex justify-between w-full px-2">
          <Typography variant="h" size="lg" width="bold">
            {inningsData.teamData.title}
          </Typography>
          <div className="flex gap-2 items-center">
            <Typography variant="p" size="sm">
              Overs {inning.overs}.{inning.balls}
            </Typography>
            <Typography variant="p" size="xl" width="semibold">
              {inning.runs}-{inning.wickets}
            </Typography>
          </div>
        </div>
      </div>

      <div className="mt-2 flex gap-4 px-2">
        <div className="w-1/2 divide-y divide-dark-400">
          {inningsData.topBatsmen.map((player: any) => (
            <div key={player._id} className="flex justify-between items-center">
              <Typography variant="h" size="sm">
                {player.player.playerInfo.name}
              </Typography>
              <Typography variant="h" size="sm">
                {player.runs} ({player.balls})
              </Typography>
            </div>
          ))}
        </div>
        <div className="w-1/2 divide-y divide-dark-400">
          {inningsData.topBowlers.map((player: any) => (
            <div key={player._id} className="flex justify-between items-center">
              <Typography variant="h" size="sm">
                {player.player.playerInfo.name}
              </Typography>
              <Typography variant="h" size="sm">
                {player.wickets}-{player.runs} ({player.overs}.{player.balls})
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </CardBase>
  );
};
