import * as React from "react";

export interface _ICommonContext_ {
  socketManager?: any;
  user?: any;
  setUser?: any;
  dreamTeam?: any;
  setDreamTeam?: any;
  dreamPlayers?: any;
  setDreamPlayers?: any;
  getDreamTeamData?: any;
  socket?: any;
}
export const commonContext: _ICommonContext_ = {
  socketManager: null,
  user: null,
  setUser: null,
  dreamTeam: null,
  setDreamTeam: null,
  dreamPlayers: null,
  setDreamPlayers: null,
  getDreamTeamData: null,
  socket: null,
};
const CommonContext = React.createContext(commonContext);

export const CommonContextProvider = CommonContext.Provider;
export default CommonContext;
