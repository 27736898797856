export const CardBase = ({
  children,
  className,
  backgroundImage,
  padding = true,
  backgroundColor,
}: any) => {
  return (
    <div
      className={`bg-dark-300 rounded`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: backgroundColor,
      }}
    >
      <div
        className={`bg-black bg-opacity-60 rounded ${
          padding ? "p-4" : ""
        } ${className}`}
      >
        {children}
      </div>
    </div>
  );
};
