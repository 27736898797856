import { TbCricket } from "react-icons/tb";
import Typography from "../data-display/Typography";

export const GridButton = (props: any) => {
  const { handleClick, icon, label, disabled, ...buttonProps } = props;

  return (
    <button
      className={`w-40 h-40 flex flex-col gap-2 items-center justify-center ${
        disabled ? "bg-dark-100" : "bg-dark-300 hover:bg-dark-400"
      } cursor-pointer`}
      onClick={handleClick}
      disabled={disabled}
      {...buttonProps}
    >
      {icon}
      <Typography variant="h" size="2xl" width="bold">
        {label}
      </Typography>
    </button>
  );
};
