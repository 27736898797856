import cn from "classnames";

export const TeamLogo = ({ logo, size = 10, className }: any) => {
  return (
    <div
      className={cn(
        `bg-dark-500 rounded-full border-dark-400 border-4 flex items-center justify-center`,
        className
      )}
    >
      <img src={logo} alt="team logo" width={size} className={`object-cover`} />
    </div>
  );
};
