import { CardBase } from "../cards/CardBase";
import Typography from "./Typography";

export const StatsItem = ({ label, value }: any) => {
  return (
    <CardBase className="w-14 h-14 bg-dark-400 p-0 flex flex-col items-center justify-center">
      <Typography variant="p" size="sm" className="leading-none">
        {label}
      </Typography>
      <Typography variant="h" size="2xl" width="bold" className="leading-none">
        {value || 0}
      </Typography>
    </CardBase>
  );
};
