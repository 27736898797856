import { AiFillHome } from "react-icons/ai";
import { CgShapeHexagon } from "react-icons/cg";
import { FaHome } from "react-icons/fa";

export type TMenuRecord = Record<string, boolean>;

export const resolveItems = () => {
  const items = [
    {
      title: "Home",
      Icon: <FaHome size={28} />,
      link: "/",
      accessor: "home",
    },
    {
      title: "Dream Team",
      Icon: <CgShapeHexagon size={28} />,
      link: "/dream-team",
      accessor: "dream-team",
    },
  ];

  return items;
};
