import React, { useCallback } from "react";

type TProps = any;
export const Badge = ({
  status,
  children,
  appearance,
  variant,
  className,
}: TProps) => {
  const appearanceStyle = useCallback(() => {
    switch (appearance) {
      case "filled":
        return filledStyle();
      default:
        return outlineStyle();
    }
  }, [appearance]);

  const sizeStyle = useCallback(() => {
    switch (variant) {
      case "sm":
        return "text-sm px-2";

      case "lg":
        return "text-lg px-5";

      default:
        return "text-base px-4";
    }
  }, [variant]);

  const outlineStyle = useCallback(() => {
    switch (status?.toLowerCase()) {
      case "warning":
        return "bg-[#FFFAEB] text-[#F79009] border border-solid border-[#FEDF89]";

      case "success":
        return "bg-[#ECFDF3] text-[#12B76A] border border-solid border-[#A6F4C5]";

      case "info":
        return "bg-[#E6F1FC] text-[#006CCF] border border-solid border-[#8AC0F2] ";

      case "error":
        return "bg-[#FEF3F2] text-[#F04438]  border border-solid border-[#FECDCA]";

      default:
        return "bg-neutral-100 text-[#667085] border border-solid border-[#D0D5DD]";
    }
  }, [status]);

  const filledStyle = useCallback(() => {
    switch (status?.toLowerCase()) {
      case "info":
        return "bg-[#016DCF] text-[#FFF]";

      case "warning":
        return "bg-[#F79009] text-[#FFF]";

      case "success":
        return "bg-[#12B76A] text-[#FFF]";

      default:
        return "bg-[#016DCF] text-[#FFF]";
    }
  }, [status]);

  return (
    <span
      className={`
      font-medium px-4
      rounded-2xl
      ${sizeStyle()}
      ${appearanceStyle()}
      ${className}
  `}
    >
      {children}
    </span>
  );
};
