import { useContext, useEffect, useState } from "react";
import CommonContext from "../../context/commonContext";
import { getEligibleBowlers } from "../../utils/match";
import Button from "../common/buttons/Button";
import Typography from "../common/data-display/Typography";
import { Modal } from "../common/modal/Modal";
import { PlayerListCard } from "../player-card/PlayerListCard";
import { SquadPlayerCard } from "../player-card/SquadPlayerCard";

const OPONEN_TEAM: any = {
  teamA: "teamB",
  teamB: "teamA",
};

export const SelectBowlerModal = (props: any) => {
  const { user, socketManager } = useContext(CommonContext);
  const {
    matchData,
    isLoading,
    team,
    open,
    handleClose,
    currentSquad,
    selectBatsmanType,
    liveData,
    currentTeam,
  } = props;
  const [selectedPlayer, setSelectedPlayer] = useState<any>(null);

  useEffect(() => {
    // setCurrentSquad(matchData?.squad[team]?.playingXI);
  }, [open]);

  const nextPlayers = getEligibleBowlers(
    currentSquad,
    matchData.innings[liveData[OPONEN_TEAM[currentTeam]]?.inning]?.bowlingOrder,
    matchData?.overs / 5
  );

  const handleSelectBatsman = (player: any) => {
    setSelectedPlayer(player);
  };

  const handleSubmit = async () => {
    const dataToUpdate: any = {
      bowler: selectedPlayer?._id,
      team: currentTeam,
    };

    await socketManager.send("dreamTeamMatch->updateMatchData", {
      args: {
        id: matchData?._id,
      },
      data: dataToUpdate,
    });

    handleClose();
  };

  const footer = (
    <div className="flex justify-end gap-2">
      <div
        style={{
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Button disabled={!selectedPlayer} onClick={handleSubmit}>
          Select
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={
        <div className="flex gap-2 items-center">
          <img src={team?.theme?.logo} className="w-9 h-9" />
          <Typography size="xl">{team?.title}</Typography>
        </div>
      }
      footer={footer}
    >
      <div className="space-y-2 justify-center">
        {nextPlayers
          ?.sort(
            (a: any, b: any) =>
              b?.playerInfo?.bowlingLevel - a?.playerInfo?.bowlingLevel
          )
          ?.map((player: any) => (
            <div className="" key={player?._id}>
              <PlayerListCard
                playerInfo={player?.playerInfo}
                id={player?._id}
                key={player?._id}
                team={team}
                value={player?.playerInfo?.bowlingLevel}
                isActive={player?._id === selectedPlayer?._id}
                onClick={() => handleSelectBatsman(player)}
              />
            </div>
          ))}
      </div>
    </Modal>
  );
};
