import React, { ReactNode } from "react";

interface TypographyProps {
  variant?: "span" | "h" | "p";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
  className?: string;
  children: ReactNode;
  color?: "primary"; // Add more color options as needed
  width?: "light" | "semibold" | "bold";
}

const Typography: React.FC<TypographyProps> = ({
  variant = "span",
  size = "md",
  className = "",
  children,
  color = "primary",
  width,
}) => {
  // Define Tailwind CSS classes based on props
  const variantClasses: any = {
    span: "text-base",
    h: "text-2xl",
    p: "text-lg",
  };

  const sizeClasses: any = {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-base",
    lg: "text-lg",
    xl: "text-xl",
    "2xl": "text-2xl",
    "3xl": "text-3xl",
  };

  const colorClasses: any = {
    primary: "text-primary",
    // Add more color options as needed
  };

  const widthClasses: any = {
    light: "font-light",
    semibold: "font-semibold",
    bold: "font-bold",
  };

  // Combine classes based on props
  const combinedClasses = `${variantClasses[variant]} ${sizeClasses[size]} ${
    colorClasses[color]
  } ${width ? widthClasses[width] : ""} ${className}`;

  // Render the Typography component using JSX
  return React.createElement(
    variant,
    { className: combinedClasses },
    children
  ) as JSX.Element;
};

export default Typography;
