import { CardBase } from "../../components/common/cards/CardBase";
import { motion, useAnimation } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { MatchLayout } from "../../components/dream-team/MatchLayout";
import { SPINNER } from "../../constants/images";
import { GetQuickMatchData } from "../../service/restServices";
import { useParams } from "react-router-dom";
import CommonContext, { commonContext } from "../../context/commonContext";
import { PlayingXIModal } from "../../components/match/PlayingXIModal";
import TossComponent from "../../components/match/TossComponent";
import { TeamLogo } from "../../components/common/data-display/TeamLogo";
import Typography from "../../components/common/data-display/Typography";
import { ScoreboardPlayerCard } from "../../components/player-card/ScoreboardPlayerCard";
import { LiveScoreboard } from "../../components/match/LiveScoreboard";
import {
  getBestBatsmanForNextToBat,
  getBestBowlerForNextOver,
} from "../../utils/match";
import { BiSolidDownArrow } from "react-icons/bi";
import { FaPlay } from "react-icons/fa";
import { MatchSummary } from "../../components/match/MatchSummary";

export const DreamTeamQuickPlayPage = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const { socket, socketManager, user, dreamTeam } = useContext(CommonContext);
  const [matchData, setMatchData] = useState<any>(null);
  const [currentTeamLiveData, setCurrentTeamLiveData] = useState<any>(null);
  const [oponentTeamLiveData, setOponentTeamLiveData] = useState<any>(null);
  console.log(
    "💡 | file: DreamTeamQuickPlayPage.tsx:16 | matchData:",
    matchData
  );

  // const [liveData, setLiveData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [currentTeam, setCurrentTeam] = useState("teamA");
  console.log("💡 | currentTeam:", currentTeam);
  const [oponentTeam, setOponentTeam] = useState("teamB");

  const [lastRotate, setLastRotate] = useState(0);
  const id = useParams().id;
  const controls = useAnimation();

  useEffect(() => {
    // If TeamB is Bot then detect the end of over and select a random bowler
    if (
      !oponentTeamLiveData?.scorer &&
      !isLoading &&
      !currentTeamLiveData?.bowler?.id &&
      currentTeamLiveData.status !== "completed"
    ) {
      setTimeout(() => {
        const botInning =
          matchData?.innings?.first?.battingTeam === dreamTeam?._id
            ? "first"
            : "second";

        console.log(
          "💡 | matchData?.innings[botInning]?.bowlingOrder:",
          matchData?.innings[botInning]?.bowlingOrder
        );
        const nextBowler = getBestBowlerForNextOver(
          matchData?.squad?.teamB?.playingXI,
          matchData?.innings[botInning]?.bowlingOrder,
          Number((matchData?.overs / 5).toFixed(0))
        );
        console.log("💡 | nextBowler:", nextBowler);

        if (nextBowler) {
          socketManager.send("dreamTeamMatch->updateMatchData", {
            args: {
              id: id,
            },
            data: {
              team: "teamB",
              bowler: nextBowler._id,
            },
          });
        }
      }, 2000);
    }
  }, [currentTeamLiveData?.bowler]);

  useEffect(() => {
    if (
      !oponentTeamLiveData?.scorer &&
      !isLoading &&
      (!oponentTeamLiveData?.batsman?.striker?.id ||
        !oponentTeamLiveData?.batsman?.nonStriker?.id) &&
      oponentTeamLiveData.status !== "completed"
    ) {
      setTimeout(() => {
        const botInning =
          matchData?.innings?.first?.battingTeam === dreamTeam?._id
            ? "second"
            : "first";

        console.log(
          "💡 | matchData?.innings[botInning]?.battingOrder:",
          matchData?.innings[botInning]?.battingOrder
        );

        const nextBatsman = getBestBatsmanForNextToBat(
          matchData?.squad?.teamB?.playingXI,
          matchData?.innings[botInning]?.battingOrder,
          oponentTeamLiveData?.batsman?.nonStriker?.id
        );
        console.log("💡 | nextBatsman:", nextBatsman);

        const nextNonStriker = getBestBatsmanForNextToBat(
          matchData?.squad?.teamB?.playingXI,
          matchData?.innings[botInning]?.battingOrder,
          oponentTeamLiveData?.batsman?.striker?.id,
          !oponentTeamLiveData?.batsman?.striker?.id ? 1 : 0
        );

        if (nextBatsman || nextNonStriker) {
          socketManager.send("dreamTeamMatch->updateMatchData", {
            args: {
              id: id,
            },
            data: {
              team: "teamB",
              striker: !oponentTeamLiveData?.batsman?.striker?.id
                ? nextBatsman._id
                : null,
              nonStriker: !oponentTeamLiveData?.batsman?.nonStriker?.id
                ? nextNonStriker._id
                : null,
            },
          });
        }
      }, 2000);
    }
  }, [
    oponentTeamLiveData?.batsman?.striker,
    oponentTeamLiveData?.batsman?.nonStriker,
  ]);

  const getWinner = () => {
    const winerTeam =
      matchData?.teams?.teamA?._id === matchData?.result?.winner
        ? matchData?.teams?.teamA?._id
        : matchData?.teams?.teamB?._id;
    return {
      ...matchData?.result,
      winner: winerTeam,
    };
  };

  const getMatchData = async () => {
    const response = await socketManager.send("dreamTeamMatch->getMatchData", {
      args: {
        id: id,
      },
      options: {},
    });

    setMatchData(response?.data);
    // setLiveData(response?.data?.liveData);

    const _currentTeam =
      response?.data?.teams?.teamA?._id === dreamTeam?._id ? "teamA" : "teamB";

    const _oponentTeam =
      response?.data?.teams?.teamA?._id === dreamTeam?._id ? "teamB" : "teamA";

    setCurrentTeamLiveData(response?.data?.liveData[_currentTeam]);
    setOponentTeamLiveData(response?.data?.liveData[_oponentTeam]);
    setCurrentTeam(_currentTeam);
    setOponentTeam(_oponentTeam);
    setIsLoading(false);
  };

  useEffect(() => {
    if (dreamTeam?._id && user) {
      getMatchData();
    }
  }, [socket, dreamTeam, user]);

  useEffect(() => {}, []);

  const handleSpinClick = async () => {
    if (!currentTeamLiveData?.spinning) {
      const striker = matchData?.squad[currentTeam]?.playingXI?.find(
        (batsman: any) =>
          batsman?._id === currentTeamLiveData?.batsman?.striker?.id
      );

      const bowler = matchData?.squad[oponentTeam]?.playingXI?.find(
        (bowler: any) => bowler?._id === currentTeamLiveData?.bowler?.id
      );

      setCurrentTeamLiveData({
        ...currentTeamLiveData,
        spinning: true,
      });

      const response = await socketManager.send("dreamTeamMatch->playMatch", {
        data: {
          match: matchData?._id,
          team: dreamTeam._id,
          bat: striker?.playerInfo?.battingLevel,
          bowl: bowler?.playerInfo?.bowlingLevel,
        },
      });
      console.log("💡 | response:", response);
    }
  };

  socketManager.socket.removeEventListener("dream-team-match");

  // let fileChunk = null

  socketManager.socket.on("dream-team-match", ({ data, meta }: any) => {
    console.log("💡 | meta:", meta);
    console.log("💡 | file: DreamTeamQuickPlayPage.tsx:101 | data:", data);
    if (meta.team === currentTeam) {
      setCurrentTeamLiveData(data.liveData[currentTeam]);
    } else if (meta.team === oponentTeam) {
      setOponentTeamLiveData(data.liveData[oponentTeam]);
    }
    setMatchData({
      ...matchData,
      innings: data?.innings,
      ready: data?.ready,
      status: data?.status,
    });
  });

  const botPlay = async () => {
    if (!oponentTeamLiveData?.spinning && !isLoading) {
      const striker = matchData?.squad[oponentTeam]?.playingXI?.find(
        (batsman: any) =>
          batsman?._id === oponentTeamLiveData.batsman?.striker?.id
      );

      const bowler = matchData?.squad[currentTeam]?.playingXI?.find(
        (bowler: any) => bowler?._id === oponentTeamLiveData.bowler?.id
      );

      setOponentTeamLiveData({
        ...oponentTeamLiveData,
        spinning: true,
      });

      if (!striker || !bowler) return;

      const res = await socketManager.send("dreamTeamMatch->playMatch", {
        data: {
          match: matchData?._id,
          team: matchData?.teams[oponentTeam]?._id,
          bat: striker?.playerInfo?.battingLevel,
          bowl: bowler?.playerInfo?.bowlingLevel,
        },
      });
      console.log("💡 | res:", res);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        botPlay();
      }, 2000);
    }
  }, [
    oponentTeamLiveData?.spinning,
    oponentTeamLiveData?.bowler?.id,
    oponentTeamLiveData?.batsman?.striker?.id,
    oponentTeamLiveData?.batsman?.nonStriker?.id,
  ]);

  return (
    <MatchLayout matchData={matchData}>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <>
          {matchData?.status === "live" ? (
            <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
              <div className="w-full md:w-1/2 bottom-2 px-2 md:px-0">
                <LiveScoreboard
                  matchData={matchData}
                  liveData={{
                    [currentTeam]: currentTeamLiveData,
                    [oponentTeam]: oponentTeamLiveData,
                  }}
                  currentTeam={currentTeam}
                  oponentTeam={oponentTeam}
                />
              </div>
              <div className="w-full md:w-1/2 md:relative absolute -bottom-[10%] scale-100 md:scale-100">
                <div className="flex justify-center items-center px-4 mb-6 w-full relative">
                  <div className="absolute z-20 -top-2">
                    <BiSolidDownArrow className="text-orange-600" size={50} />
                  </div>
                  <button
                    onClick={handleSpinClick}
                    className="absolute z-20 pl-2"
                  >
                    <FaPlay
                      size={40}
                      className="text-orange-600 cursor-pointer"
                    />
                  </button>
                  <img
                    src={SPINNER}
                    className={
                      currentTeamLiveData?.spinning ? "animate-spin-match" : ""
                    }
                    style={{
                      transform: `rotate(${currentTeamLiveData?.lastSpinPosition}deg)`,
                      transition: "transform 0.5s ease-in-out",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {matchData?.status === "completed" ? (
            // <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
            //   {getWinner()?.winner?.title}
            // </div>

            <MatchSummary matchData={matchData} />
          ) : null}

          {!matchData?.ready?.[user?._id] && !isLoading ? (
            <PlayingXIModal
              liveData={{
                [currentTeam]: currentTeamLiveData,
                [oponentTeam]: oponentTeamLiveData,
              }}
              matchData={matchData}
            />
          ) : null}
          <PlayingXIModal
            liveData={{
              [currentTeam]: currentTeamLiveData,
              [oponentTeam]: oponentTeamLiveData,
            }}
            matchData={matchData}
          />
        </>
      )}
    </MatchLayout>
  );
};
