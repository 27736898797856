type TSquad = {
  _id: string;
  playerInfo: {
    _id: string;
    name: string;
    battingLevel: number;
    bowlingLevel: number;
  } & any;
} & any;

type TBowlingOrder = {
  id: string;
  overs: number;
} & any;

export const getEligibleBowlers = (
  squad: TSquad[],
  bowlingOrders: TBowlingOrder[],
  oversPerBowler: number
) => {
  console.log("💡 | bowlingOrders:", bowlingOrders);
  let _bowlers = [...squad];

  // filter out all bowlers who completed limit of overPerBowler given in bowlingOrders
  _bowlers = _bowlers.filter((b) => {
    const _bowler = bowlingOrders.find((bo) => bo?.id === b?._id);

    if (!_bowler) return true;

    return _bowler.overs < oversPerBowler;
  });

  // filter out that one bowler who bowled the last over/belong to last item in bowlingOrders
  _bowlers = _bowlers.filter((b) => {
    const lastBowler = bowlingOrders[bowlingOrders.length - 1];
    console.log("💡 | lastBowler:", lastBowler);

    if (!lastBowler) return true;

    return b._id !== lastBowler.id;
  });

  // sort by bowlingLevel high to low
  _bowlers.sort(
    (a, b) => b.playerInfo.bowlingLevel - a.playerInfo.bowlingLevel
  );

  // rest of the code...
  return _bowlers;
};

export const getEligibleBatsmen = (
  squad: TSquad[],
  battingOrders: any[],
  onField: string
) => {
  console.log("💡 | battingOrders:", battingOrders);
  let _batsmen = [...squad];

  _batsmen = _batsmen.filter((b) => {
    const _batsman = battingOrders.find((bo: any) => bo.id === b._id);

    if (!_batsman && onField !== b._id) return true;
  });

  _batsmen.sort(
    (a, b) => b.playerInfo.battingLevel - a.playerInfo.battingLevel
  );

  return _batsmen;
};

export const getBestBowlerForNextOver = (
  squad: TSquad[],
  bowlingOrders: TBowlingOrder[],
  oversPerBowler: number
) => {
  const _bowlers = getEligibleBowlers(squad, bowlingOrders, oversPerBowler);
  return _bowlers[0];
};

export const getBestBatsmanForNextToBat = (
  squad: TSquad[],
  battingOrders: number[],
  onField: string,
  index?: number
) => {
  console.log("💡 | index:", index);
  const _batsmen = getEligibleBatsmen(squad, battingOrders, onField);
  return _batsmen[index || 0];
};

export const getMatchResult = (result: any, teams: any) => {
  const winner =
    result?.winner === teams.teamA?._id ? teams.teamA : teams.teamB;

  return `${winner?.title} won the match by ${result?.runs} runs & ${result?.wickets} wickets!`;
};

export const getTopRunScorers = (
  squad: TSquad[],
  battingOrder: any[],
  counts?: number
) => {
  const _batsmen = battingOrder.sort((a, b) => {
    // sort by runs scored high to low, if same then sort by balls faced

    if (a.runs < b.runs) return 1;
    if (a.runs > b.runs) return -1;
    if (a.balls > b.balls) return 1;
    if (a.balls < b.balls) return -1;
    return 0;
  });

  const _topScorers = _batsmen.slice(0, counts || _batsmen.length).map((b) => {
    const findPlayer = squad.find((s) => s._id === b.id);

    return {
      ...b,
      player: findPlayer,
    };
  });

  return _topScorers;
};

export const getTopWicketTakers = (
  squad: TSquad[],
  bowlingOrder: any[],
  counts?: number
) => {
  const _bowlers = bowlingOrder.sort((a, b) => {
    // sort by wickets taken high to low, if same then sort by runs given

    if (a.wickets < b.wickets) return 1;
    if (a.wickets > b.wickets) return -1;
    if (a.runs > b.runs) return 1;
    if (a.runs < b.runs) return -1;
    return 0;
  });

  const _topWicketTakers = _bowlers
    .slice(0, counts || _bowlers.length)
    .map((b) => {
      const findPlayer = squad.find((s) => s._id === b.id);

      return {
        ...b,
        player: findPlayer,
      };
    });

  return _topWicketTakers;
};
