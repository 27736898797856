import { useSearchParams } from 'react-router-dom';

export type TResolveToConfig = {
  key: string;
  defaultLink: string;
  resolver?(title?: string): string;
};
export const useResolveTo = (title: string, config?: TResolveToConfig) => {
  const [searchParams] = useSearchParams();
  if (config) {
    const { key, defaultLink, resolver } = config;
    if (resolver) {
      return title === defaultLink ? resolver() : resolver(title);
    }
    if (title === defaultLink) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, title);
    }
    return `?${searchParams.toString()}`;
  } else {
    return null;
  }
};
