import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CgClose } from "react-icons/cg";

export const Modal = ({
  onClose,
  open,
  children,
  title,
  footer,
  maxWidth = 1000,
  showTitle = true,
  ...props
}: any) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        onClose={() => null}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Panel
              as="div"
              className="fixed inset-0 bg-black bg-opacity-50"
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom w-full bg-dark-200 shadow shadow-dark-300 border-dark-100 text-left overflow-hidden transform transition-all`}
              style={{ maxWidth: maxWidth }}
            >
              {/* Modal content */}
              {showTitle && (
                <div className="w-full flex justify-between items-center bg-dark-300 border-b border-dark-100 px-2 md:px-3 py-1 md:py-3">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-semibold text-gray-200 "
                  >
                    {title}
                  </Dialog.Title>
                  {onClose ? (
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={onClose}
                    >
                      <CgClose />
                    </button>
                  ) : null}
                </div>
              )}
              <div className="w-full p-3 max-h-[70vh] overflow-y-auto">
                {children}
              </div>
              {footer ? (
                <div className="w-full items-center bg-dark-300 border-b border-dark-100 px-2 md:px-3 py-1 md:py-3">
                  {footer}
                </div>
              ) : null}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
