// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAX7LoD-B_PyJxfAo58fgWRv5pB64hoC4g",
  authDomain: "spin-the-wicket-dev.firebaseapp.com",
  projectId: "spin-the-wicket-dev",
  storageBucket: "spin-the-wicket-dev.appspot.com",
  messagingSenderId: "941304290406",
  appId: "1:941304290406:web:ce4f5eb8113cb4183b61a7",
  measurementId: "G-F6CC21L6HN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
