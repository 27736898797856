import cn from 'classnames';
import { FC } from 'react';
import { TChildrenProps } from './types';
import { motion } from 'framer-motion';

export const Children: FC<TChildrenProps> = ({
  isSelected,
  selectedId,
  textClassValue,
  selectedClassValue,
  children,
}) => {
  return (
    <>
      {isSelected && <motion.div className={cn(selectedClassValue)} layoutId={selectedId} />}
      <span className={cn(textClassValue)}>{children}</span>
    </>
  );
};
