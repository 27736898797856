import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { resolveItems } from "./config";
import CommonContext from "../../context/commonContext";
import { useNavigate } from "react-router-dom";

type TProps = any;
export const Layout: FC<TProps> = ({ children }: any) => {
  const initSidebarItems = useMemo(resolveItems, []);
  const navigate = useNavigate();
  const { user } = useContext(CommonContext);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  const [sidebarItems] = useState(initSidebarItems);

  return (
    <div>
      <Navbar />
      <div className="bg-dark-100 w-full justify-center flex">
        <div className="w-full max-w-5xl flex flex-col md:flex-row gap-4 px-4">
          {/* <div className="hidden md:block">
            <Sidebar sidebarItems={sidebarItems} />
          </div> */}
          <main className="h-[calc(100vh-3rem)] md:h-[calc(100vh-4rem)] w-full overflow-y-auto py-4 md:mb-0">
            <div className="w-full">{children}</div>
          </main>
        </div>
      </div>
      {/* <div className="md:hidden absolute bottom-0 w-full">
        <div className="flex justify-center">
          <Sidebar sidebarItems={sidebarItems} mobile={true} />
        </div>
      </div> */}
    </div>
  );
};
