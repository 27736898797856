import { useContext, useEffect, useState } from "react";
import CommonContext from "../../context/commonContext";
import Typography from "../common/data-display/Typography";
import { Modal } from "../common/modal/Modal";
import { SquadPlayerCard } from "../player-card/SquadPlayerCard";

export const PlayingXIModal = (props: any) => {
  const { user, socketManager } = useContext(CommonContext);
  const { matchData, isLoading } = props;
  const [visible, setVisible] = useState(false);
  const [currentSquad, setCurrentSquad] = useState([]);
  const [currentTeam, setCurrentTeam] = useState<any>(null);

  const toggle = async () => {
    setVisible(false);
    const dataToUpdate: any = {};
    dataToUpdate[`ready.${user?._id}`] = true;
    // const response = await UpdateQuickMatch(liveData?.id, dataToUpdate);

    // console.log(response);

    const response = await socketManager.send(
      "dreamTeamMatch->updateMatchData",
      {
        args: {
          id: matchData?._id,
        },
        data: dataToUpdate,
      }
    );
    console.log("💡 | file: PlayingXIModal.tsx:31 | response:", response);
  };

  const setTeamData = (squad: any, team: any) => {
    setCurrentSquad(squad);
    setCurrentTeam(team);
  };

  const skipSquad = () => {
    if (currentTeam?._id === matchData?.teams.a) {
      setTeamData(matchData?.teams.b, matchData.teams[1]);
    } else {
      toggle();
    }
  };

  useEffect(() => {
    if (!matchData?.ready?.[user?._id] && !isLoading && matchData) {
      setVisible(true);
      if (matchData) {
        setTeamData(matchData?.squad?.teamA?.playingXI, matchData.teams?.teamA);
      }

      setTimeout(() => {
        setTeamData(matchData?.squad?.teamB?.playingXI, matchData.teams?.teamB);
        setTimeout(() => {
          toggle();
        }, 5000);
      }, 5000);
    }
  }, [matchData, isLoading]);

  return (
    <Modal
      onClsoe={skipSquad}
      open={visible}
      title={
        <div className="flex gap-2 items-center">
          <img src={currentTeam?.theme?.logo} className="w-9 h-9" />
          <Typography size="xl">{currentTeam?.title}</Typography>
        </div>
      }
    >
      <div className="@container">
        <div className="grid grid-cols-4 @md:grid-cols-6">
          {currentSquad?.map((player: any) => (
            <div className="" key={player?._id}>
              <SquadPlayerCard
                playerInfo={player?.playerInfo}
                id={player?._id}
                key={player?._id}
                team={currentTeam}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
