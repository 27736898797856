import { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CommonContext from "../../context/commonContext";
import {
  CreateDreamTeam,
  GetAllThemes,
  GetRandomCaptains,
} from "../../service/restServices";
import Button from "../common/buttons/Button";
import Typography from "../common/data-display/Typography";
import InputField from "../common/forms/InputField";
import { Modal } from "../common/modal/Modal";
import { SquadPlayerCard } from "../player-card/SquadPlayerCard";

export const CreateDreamTeamModal = (props: any) => {
  const { onClose, open, ...rest } = props;

  const { user, setDreamTeam, setDreamPlayers, getDreamTeamData } =
    useContext(CommonContext);
  const [title, setTitle] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [theme, setTheme] = useState(null);
  const [themes, setThemes] = useState<any>([]);
  const [step, setStep] = useState(1);
  const [captains, setCaptains] = useState([]);
  const [captain, setCaptain] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState(null);
  const [squad, setSquad] = useState([]);

  const handleSubmit = async () => {
    console.log({
      theme,
      title,
      code,
      captain,
      manager: user?._id,
    });
    if (!theme || !title || !code || !captain) {
      setError("Please Insert Team Name, Select Theme and Captain");
    } else {
      setLoading(true);
      setError(null);

      const data = {
        theme,
        title,
        code,
        captain,
        manager: user?._id,
      };

      const response = await CreateDreamTeam(data);

      console.log(response);

      if (response.status) {
        setTeam(response?.data?.team);
        setSquad(response?.data?.squad);
        setStep(4);
      } else {
        setError(response?.message);
      }
      setLoading(false);
    }
  };

  const onFinish = async () => {
    await getDreamTeamData();
    onClose();
  };

  const getThemes = async () => {
    const response = await GetAllThemes({ themeType: "team" });

    console.log(response);

    if (response?.status) {
      setThemes(response?.data);
    }
  };

  const getCaptains = async () => {
    const captainsResponse = await GetRandomCaptains();

    if (captainsResponse?.status) {
      setCaptains(captainsResponse?.data);
    }
  };

  useEffect(() => {
    getThemes();
    getCaptains();
  }, []);

  const footer = (
    <div className="flex justify-end gap-2">
      {error && <Typography className="text-red-500">{error}</Typography>}
      {step === 4 ? (
        <div
          style={{
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Button onClick={onFinish}>Get Started</Button>
        </div>
      ) : (
        <div className="flex gap-2">
          <Button
            onClick={() => {
              if (step === 1) {
                onClose();
              } else {
                setStep(step - 1);
              }
            }}
          >
            {step === 1 ? "Cancel" : "Back"}
          </Button>

          <Button
            onClick={() => {
              if (step === 3) {
                handleSubmit();
              } else {
                setStep(step + 1);
              }
            }}
          >
            {step === 3 ? "Create" : "Next"}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Create Dream Team"
      maxWidth={400}
      footer={footer}
    >
      <div>
        {step === 1 && (
          <>
            <InputField
              label="Name"
              value={title}
              onChange={(e: string) => setTitle(e)}
              placeholder="Dream Team Name"
            />

            <InputField
              label="Code"
              value={code}
              onChange={(e: string) => setCode(e)}
              placeholder="Dream Team Code"
            />
          </>
        )}

        {step === 2 && (
          <div className="mt-6">
            <Typography variant="h" className="text-gray-400">
              Select Theme
            </Typography>
            <div className="p-2 mt-2 bg-dark-100  border border-dark-400 grid grid-cols-3 gap-2">
              {themes.map((item: any) => (
                <div
                  key={item._id}
                  className={` ${theme === item._id ? "shadow" : ""}`}
                  style={{
                    backgroundColor: item.color,
                    border: theme === item._id ? "2px solid white" : "none",
                  }}
                  onClick={() => setTheme(item._id)}
                >
                  <div className="bg-black bg-opacity-70 p-2 ">
                    <img src={item.logo} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="mt-6">
            <Typography variant="h" className="text-gray-400">
              Select Captain
            </Typography>
            <div className="p-2 mt-2 bg-dark-100  border border-dark-400 grid grid-cols-3 gap-2">
              {captains.map((cap: any) => (
                <SquadPlayerCard
                  playerInfo={cap}
                  id={cap?._id}
                  key={cap?._id}
                  onClick={() => setCaptain(cap?._id)}
                  isActive={captain === cap?._id}
                />
              ))}
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="mt-6 flex flex-col gap-2 items-center justify-center">
            <Typography
              variant="h"
              size="3xl"
              className="text-gray-200"
              width="bold"
            >
              Congratulations!
            </Typography>

            <img
              src={themes.find((t: any) => t._id === theme)?.logo}
              className="w-64 mt-4"
            />

            <Typography variant="h" size="3xl" className="text-gray-100">
              {title}
            </Typography>
          </div>
        )}
      </div>
    </Modal>
  );
};
