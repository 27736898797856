import { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CommonContext from "../../context/commonContext";
import {
  CreateDreamTeam,
  CreateQuickMatch,
  GetAllThemes,
  GetRandomCaptains,
} from "../../service/restServices";
import Button from "../common/buttons/Button";
import Typography from "../common/data-display/Typography";
import InputField from "../common/forms/InputField";
import { Modal } from "../common/modal/Modal";
import { SquadPlayerCard } from "../player-card/SquadPlayerCard";
import { BiSolidCricketBall } from "react-icons/bi";
import { TbCricket } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GridButton } from "../common/buttons/GridButton";

export const PlayModal = (props: any) => {
  const { onClose, open, ...rest } = props;
  const [loadingQuickMatch, setLoadingQuickMatch] = useState(false);

  const navigate = useNavigate();
  const {
    user,
    dreamPlayers,
    dreamTeam,
    setDreamTeam,
    setDreamPlayers,
    getDreamTeamData,
  } = useContext(CommonContext);
  const [step, setStep] = useState(1);

  const handleStartQuickMatch = async (mode: string) => {
    setLoadingQuickMatch(true);

    const data = {
      team: dreamTeam?._id,
      overs: 5,
      user: user?._id,
      matchMode: mode,
    };

    const response = await CreateQuickMatch(data);
    console.log("💡 | file: DreamTeamPlayPage.tsx:34 | response:", response);

    if (response.status) {
      setLoadingQuickMatch(false);
      navigate(`/dream-team/quick-play/${response.data?._id}`);
    } else {
      toast.error(response.message);
    }
    setLoadingQuickMatch(false);
  };

  return (
    <Modal open={open} onClose={onClose} title="Start Match" maxWidth={400}>
      <div>
        {step === 1 && (
          <div className="flex justify-evenly items-center w-full h-full gap-1">
            <GridButton
              icon={<BiSolidCricketBall size={50} />}
              label="Head to Head"
              handleClick={() => handleStartQuickMatch("h2h")}
            />
            <GridButton
              icon={<TbCricket size={50} />}
              label="Full Match"
              handleClick={() => handleStartQuickMatch("full")}
              disabled
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
