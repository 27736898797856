import Typography from "../common/data-display/Typography";
import cn from "classnames";
import { FaPlus } from "react-icons/fa";

export const ScoreboardOpponentPlayerCard = (props: any) => {
  const {
    playerInfo,
    team,
    isActive,
    onClick,
    stats,
    className,
    type,
    role,
    isOpponent,
  } = props;

  const getStats = () => {
    if (type === "batting") {
      return `${stats?.runs}(${stats?.balls})`;
    } else if (type === "bowling") {
      return `${stats.runs}-${stats?.wickets}(${stats?.overs}.${stats?.balls})`;
    }
  };

  const getShortName = (name: string) => {
    // if (name.length > 12) {
    return name.split(" ")[0];
    // }
    return name;
  };

  return (
    <div
      className={cn(
        "relative overflow-hidden flex justify-between items-center hover:cursor-pointer w-full",
        className,
        isActive && `rounded-full`
      )}
    >
      {playerInfo ? (
        <>
          <div
            className="flex items-center justify-between w-full px-3 py-1"
            style={{
              backgroundColor: isActive ? team?.theme?.color : "transparent",
            }}
          >
            <div className="z-10 flex  items-center gap-2">
              <Typography
                variant="p"
                width="bold"
                className="leading-none text-gray-200"
                size="xs"
              >
                {getShortName(playerInfo?.name)}
                {isActive && (
                  <Typography
                    variant="h"
                    size="xs"
                    width="bold"
                    className="text-primary-500 leading-none"
                  >
                    *
                  </Typography>
                )}
              </Typography>
            </div>
            <Typography
              variant="h"
              width="semibold"
              size="xs"
              className="leading-none text-gray-200 z-10"
            >
              {getStats()}
            </Typography>
          </div>
        </>
      ) : (
        <div className="flex w-full justify-center items-center">
          <div className=" w-full flex flex-col justify-center items-center text-center">
            {isOpponent ? (
              <Typography
                variant="span"
                size="xs"
                className="text-gray-400 leading-none"
              >
                Waiting
              </Typography>
            ) : (
              <div onClick={onClick}>
                {/* <FaPlus className="text-gray-200 mb-1" /> */}
                <Typography
                  variant="span"
                  size="xs"
                  className="text-gray-200 leading-none"
                >
                  Select {role}
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
