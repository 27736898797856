import {
  BASE_FRAME,
  PLAYER_BASE_DIMOND_BG,
  PLAYER_BASE_GOLD_BG,
  PLAYER_BASE_SILVER_BG,
  PLAYER_CARD_BOTTOM_BAR,
} from "../../constants/images";
import { getPlayerRating } from "../../utils/players";
import Typography from "../common/data-display/Typography";

const CATEGORIES = {
  DIMOND: {
    textColor: "#EAECF0",
    bg: PLAYER_BASE_DIMOND_BG,
  },
  GOLD: {
    textColor: "#0F172A",
    bg: PLAYER_BASE_GOLD_BG,
  },
  SILVER: {
    textColor: "#0F172A",
    bg: PLAYER_BASE_SILVER_BG,
  },
};

export const SquadPlayerCard = (props: any) => {
  const { playerInfo, team, isActive, onClick } = props;

  const getPlayerRole = () => {
    if (playerInfo.role?.toLowerCase() === "batsman") {
      return "BAT";
    } else if (playerInfo.role?.toLowerCase() === "bowler") {
      return "BALL";
    } else if (playerInfo.role?.toLowerCase() === "all-rounder") {
      return "ALL";
    } else {
      return "WK";
    }
  };

  const getPlayerCategory = (rating: number) => {
    if (rating >= 85) {
      return CATEGORIES.DIMOND;
    } else if (rating >= 75) {
      return CATEGORIES.GOLD;
    } else {
      return CATEGORIES.SILVER;
    }
  };

  return (
    <div
      className="relative overflow-hidden h-full w-full pb-[125%] flex justify-end items-center hover:cursor-pointer font-sm"
      onClick={onClick}
      style={{
        background: `url(${getPlayerCategory(getPlayerRating(playerInfo)).bg})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img
        src={BASE_FRAME}
        className="absolute top-0 left-0 z-40 scale-[1.01] w-full h-full"
      />

      <div className="absolute overflow-hidden h-full top-0 left-[8%] w-[84%] pt-[25%] ">
        <img
          src={playerInfo?.photo}
          alt={playerInfo?.name}
          className="w-[80%] h-auto scale-125"
        />
      </div>
      <div className="z-50 absolute @container overflow-hidden h-full top-0 left-[8%] w-[78%] pt-[20%] text-right flex flex-col">
        <span
          className="text-xl @[100px]:text-3xl @[150px]:text-4xl @sm:text-6xl text-dark-200 font-semibold leading-none"
          style={{
            lineHeight: 1,
            color: getPlayerCategory(getPlayerRating(playerInfo)).textColor,
          }}
        >
          {getPlayerRating(playerInfo).toFixed(0)}
        </span>
        <span
          className="text-sm @[100px]:text-lg @[150px]:text-xl @sm:text-3xl text-dark-200 font-semibold leading-none"
          style={{
            lineHeight: 1,
            color: getPlayerCategory(getPlayerRating(playerInfo)).textColor,
          }}
        >
          {getPlayerRole()}
        </span>
      </div>

      <div className="z-50 absolute @container overflow-hidden h-[20%] bottom-[10%] left-[8%] w-[84%] bg-opacity-30 px-1  text-center flex flex-col justify-center">
        <span
          className="text-xs @[100px]:text-lg @[150px]:text-xl @sm:text-2xl text-gray-100 font-semibold leading-none uppercase whitespace-nowrap"
          style={{
            lineHeight: 1,
          }}
        >
          {playerInfo.name}
        </span>
      </div>

      <img
        src={PLAYER_CARD_BOTTOM_BAR}
        className="z-20 absolute bottom-0 scale-[1.02]"
      />

      <div
        className="z-30 w-[85%] h-5 left-[7.5%] absolute bottom-[28%]"
        style={{
          backgroundImage: `linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0) 100%)`,
        }}
      ></div>
    </div>
  );
};
