import React, { useEffect } from "react";
import CommonContext, {
  commonContext,
  CommonContextProvider,
} from "./context/commonContext";
import { HelmetProvider } from "react-helmet-async";
import { SocketManager } from "./service/socketManager";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import "./styles/index.css";
import "swiper/css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetUserDreamTeam } from "./service/restServices";
import { jwtDecode } from "jwt-decode";
import { sortPlayersByRating } from "./utils/players";

const socket = new SocketManager(CommonContext, {
  onSend: () => {},
  onReceive: () => {},
});

function App() {
  const [user, setUser] = React.useState<any>(null);
  const [dreamTeam, setDreamTeam] = React.useState(null);
  const [dreamPlayers, setDreamPlayers] = React.useState(null);

  useEffect(() => {
    const token = localStorage.getItem("auth_token");

    if (token) {
      const user = jwtDecode(token);
      console.log("💡 | file: App.tsx:29 | user:", user);
      setUser(user);
    } else {
      setUser(null);
    }
  }, []);

  const getDreamTeamData = async () => {
    try {
      if (user) {
        const response = await GetUserDreamTeam(user?._id);
        console.log("💡 | file: DreamTeamPage.tsx:21 | response:", response);

        if (response?.status) {
          setDreamTeam(response?.data?.dreamTeam);
          setDreamPlayers(sortPlayersByRating(response?.data?.dreamPlayers));
        } else {
          setDreamTeam(null);
          setDreamPlayers(null);
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getDreamTeamData();
  }, [user]);

  return (
    <HelmetProvider>
      <CommonContextProvider
        value={{
          socketManager: socket,
          user: user,
          setUser: setUser,
          dreamTeam: dreamTeam,
          setDreamTeam: setDreamTeam,
          dreamPlayers: dreamPlayers,
          setDreamPlayers: setDreamPlayers,
          getDreamTeamData: getDreamTeamData,
        }}
      >
        <BrowserRouter>
          <Routes />
          <ToastContainer />
        </BrowserRouter>
      </CommonContextProvider>
    </HelmetProvider>
  );
}

export default App;
