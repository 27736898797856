import { FaCrown } from "react-icons/fa";
import { CardBase } from "../../components/common/cards/CardBase";
import { StatsItem } from "../../components/common/data-display/StatsItem";
import Typography from "../../components/common/data-display/Typography";
import mash1 from "../../assets/images/mash-1.png";
import mash2 from "../../assets/images/mash-2.png";
import mash3 from "../../assets/images/mash-3.png";
import { Layout } from "../../components/layout";
import { GetUserDreamTeam } from "../../service/restServices";
import { useContext, useEffect, useState } from "react";
import CommonContext from "../../context/commonContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Button from "../../components/common/buttons/Button";
import { CreateDreamTeamModal } from "../../components/dream-team/CreateDreamTeamModal";

export const DreamTeamPage = () => {
  const { dreamTeam, setDreamTeam, user } = useContext(CommonContext);
  console.log("💡 | file: DreamTeamPage.tsx:17 | dreamTeam:", dreamTeam);
  const [openCreateTeam, setOpenCreateTeam] = useState(false);

  const toggleCreateTeam = () => {
    setOpenCreateTeam(!openCreateTeam);
  };

  return (
    <Layout>
      {dreamTeam ? (
        <div className="flex flex-col gap-4">
          <CardBase
            className={`relative overflow-hidden py-10`}
            // backgroundColor={dreamTeam?.theme?.color}
          >
            <img
              src={dreamTeam?.theme?.logo}
              alt={dreamTeam?.code}
              className="absolute -right-28 -bottom-1/2 opacity-30 object-contain h-[200%]"
            />
            <div className="w-3/4 flex flex-col gap-2">
              <FaCrown />
              <Typography variant="h" size="3xl">
                {dreamTeam?.title}
              </Typography>
              <div className="flex gap-2">
                <StatsItem label="Played" value={dreamTeam?.mathces?.played} />
                <StatsItem label="Won" value={dreamTeam?.mathces?.won} />
                <StatsItem label="Lost" value={dreamTeam?.mathces?.lost} />
              </div>
            </div>
          </CardBase>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <CardBase
              // backgroundColor={dreamTeam?.theme?.color}
              padding={false}
              className="h-48 cursor-pointer overflow-hidden relative"
              // backgroundImage={mash2}
            >
              <Link to="/dream-team/play">
                <img
                  src={
                    "https://wallpapers.com/images/hd/bangladesh-cricket-team-tigers-poster-0pq86uqebgdq9zn9.jpg"
                  }
                  alt="kala-para-na"
                  className="absolute w-full opacity-30 object-cover h-[200%]"
                />
                <div className="w-full h-full  px-3 py-1 flex items-end">
                  <Typography variant="h" size="lg" width="bold">
                    Play
                  </Typography>
                </div>
              </Link>
            </CardBase>
            <CardBase
              // backgroundColor={dreamTeam?.theme?.color}
              padding={false}
              className="h-48 cursor-pointer overflow-hidden relative"
              // backgroundImage={mash1}
            >
              <Link to="/dream-team/squad">
                <img
                  src={
                    "https://w0.peakpx.com/wallpaper/566/457/HD-wallpaper-bangladesh-cwc-2019-cricket-cricket-2019-cwc-2019-icc-icc-cwc-masrafe-tigers-world-cup-thumbnail.jpg"
                  }
                  alt="kala-para-na"
                  className="absolute w-full opacity-30 object-cover h-[200%]"
                />
                <div className="w-full h-full  px-3 py-1 flex items-end">
                  <Typography variant="h" size="lg" width="bold">
                    Squad
                  </Typography>
                </div>
              </Link>
            </CardBase>

            <CardBase
              // backgroundColor={dreamTeam?.theme?.color}
              className="h-48 cursor-pointer overflow-hidden relative"
              padding={false}
            >
              <img
                src={
                  "https://png.pngtree.com/thumb_back/fh260/background/20230617/pngtree-bangladesh-cricket-team-rendered-in-3d-on-a-green-background-image_3632731.jpg"
                }
                alt="kala-para-na"
                className="absolute w-full opacity-30 object-cover h-[100%] object-center"
              />
              <div className="w-full h-full  px-3 py-1 flex items-end">
                <Typography variant="h" size="lg" width="bold">
                  Market
                </Typography>
              </div>
            </CardBase>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[calc(100vh-4rem)] flex-col">
          <Typography variant="h" size="lg" width="bold">
            No dream team found
          </Typography>
          <Button onClick={toggleCreateTeam}>Create dream team</Button>
        </div>
      )}

      <CreateDreamTeamModal open={openCreateTeam} onClose={toggleCreateTeam} />
    </Layout>
  );
};
