import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Typography from "../components/common/data-display/Typography";
import { Layout } from "../components/layout";
import { MatchCardSquare } from "../components/match-card/MatchCardSquare";
import { useMediaQuery } from "react-responsive";
import { CardBase } from "../components/common/cards/CardBase";
import { Link } from "react-router-dom";

export const HomePage = () => {
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const isTablet = useMediaQuery({ minWidth: 641, maxWidth: 1024 });

  const slidesPerView = isMobile ? 1 : isTablet ? 3 : 4;

  return (
    <Layout>
      <div>
        {/* Match Cards */}
        <Swiper
          spaceBetween={16}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          // slidesPerView={slidesPerView}
          breakpoints={{
            641: {
              // width: 576,
              slidesPerView: 2,
            },
            1024: {
              // width: 768,
              slidesPerView: 3,
            },
          }}
          autoplay={{
            delay: 500,
          }}
        >
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <SwiperSlide key={item}>
              <MatchCardSquare />
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Leagues */}

        <div className="flex gap-4 mt-4"></div>

        <Link to="/dream-team">
          <CardBase
            //   backgroundColor={dreamTeam?.theme?.color}
            className="h-48 cursor-pointer overflow-hidden relative"
            padding={false}
          >
            <img
              src={"https://wallpapercave.com/wp/wp7066870.png"}
              alt="kala-para-na"
              className="absolute w-full opacity-30 object-cover h-[100%]"
            />
            <div className="w-full h-full  px-3 py-1 flex items-end">
              <Typography variant="h" size="lg" width="bold">
                Dream Team
              </Typography>
            </div>
          </CardBase>
        </Link>

        {/* Dream Team */}
      </div>
    </Layout>
  );
};
