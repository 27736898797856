import cn from "classnames";
import { Required } from "./Required";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";

export type TInputFieldProps = any & {
  value: string;
  onChange(data: any): void;
  onKeyDown?: void;
  errorMessage: string;
  defaultValue?: string;
  disabled?: boolean;
  max?: number;
  min?: number;
  required?: boolean;
  bg?: string;
  tags?: string[];
  color?: string;
  id: string;
  label?: string;
  type?: string;
  placeholder?: string;
  isPasswordField?: boolean;
  setIsPasswordVisible?: any;
};
const InputField = ({
  id,
  label,
  onChange,
  errorMessage,
  required,
  bg,
  tags,
  color,
  className,
  isPasswordField,
  setIsPasswordVisible,
  ...props
}: any) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={cn("flex flex-col mt-4", className)}>
      {label && (
        <label
          id={id + "Label"}
          htmlFor="text"
          className="block mb-2 text-sm font-medium leading-5  text-gray-700  font-inter font-sm"
        >
          {label}
          {required && <Required />}
        </label>
      )}
      <label className="relative block">
        <div className="relative">
          <input
            {...props}
            id={id}
            key={id}
            required={required}
            className={`border ${
              errorMessage ? "border-red-300" : "border-gray-800"
            } ${bg} ${
              color ? color : "text-gray-200"
            } text-sm  focus:ring-0 focus:border-ring-0 block w-full p-2.5 font-inter font-medium bg-dark-100`}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
          {isPasswordField && (
            <div
              className="absolute inset-y-0 right-2 flex items-center cursor-pointer"
              onClick={() => {
                setShowPassword(!showPassword);
                setIsPasswordVisible(!showPassword);
              }}
            >
              {showPassword ? (
                <AiOutlineEye className="text-gray-400" size={20} />
              ) : (
                <AiOutlineEyeInvisible className="text-gray-400" size={20} />
              )}
            </div>
          )}
        </div>
      </label>
      {errorMessage && (
        <p
          id={id + "Error"}
          className="text-sm font-inter font-normal text-left text-red-500 pt-[6px]"
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default InputField;
