import Typography from "../common/data-display/Typography";

export const PlayerListCard = (props: any) => {
  const { playerInfo, team, isActive, onClick, value } = props;
  return (
    <div
      className="bg-dark-500 relative overflow-hidden w-full flex justify-end items-center hover:cursor-pointer rounded"
      onClick={onClick}
    >
      <img src={team?.theme?.logo} className="w-48 absolute -right-20" />
      <div
        className="flex items-center justify-between w-full h-full opacity-70 absolute rounded bg-dark-400"
        // style={{ backgroundColor: team?.theme?.color }}
      />
      <div
        className={`flex items-center justify-between w-full h-full absolute rounded ${
          isActive ? "opacity-90 bg-primary-700" : "opacity-60 bg-dark-200"
        }`}
      />
      <img
        src={playerInfo?.photo}
        alt={playerInfo?.name}
        className="z-30 w-10 scale-150 absolute left-0"
      />

      <div className="flex ml-14 w-full justify-between">
        <div className="z-10 w-full h-full text-left mt-1">
          <Typography
            variant="p"
            size="xl"
            width="bold"
            className="leading-none text-gray-200"
          >
            {playerInfo?.name}
          </Typography>
          <Typography
            variant="span"
            size="sm"
            className="leading-none text-gray-200"
          >
            {playerInfo?.role}
          </Typography>
        </div>

        <div className="flex justify-center items-center px-2">
          <Typography
            variant="h"
            size="3xl"
            width="bold"
            className="leading-none text-gray-200 z-30"
          >
            {value}
          </Typography>
        </div>
      </div>
    </div>
  );
};
