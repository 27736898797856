import Typography from "../common/data-display/Typography";
import cn from "classnames";
import { FaPlus } from "react-icons/fa";

export const ScoreboardPlayerCard = (props: any) => {
  const {
    playerInfo,
    team,
    isActive,
    onClick,
    stats,
    className,
    type,
    role,
    isOpponent,
    thisOver,
  } = props;

  const getStats = () => {
    if (type === "batting") {
      return `${stats?.runs}(${stats?.balls})`;
    } else if (type === "bowling") {
      return `${stats.runs}-${stats?.wickets}(${stats?.overs}.${stats?.balls})`;
    }
  };

  const getShortName = (name: string) => {
    // if (name.length > 12) {
    return name.split(" ")[0];
    // }
    // return name;
  };

  return (
    <div
      className={cn(
        "bg-dark-500 relative overflow-hidden flex justify-end items-center hover:cursor-pointer rounded",
        className
      )}
      onClick={onClick}
    >
      {playerInfo ? (
        <>
          <img
            src={team?.theme?.logo}
            className="w-64 absolute scale-125 left-4"
          />
          <div
            className="flex items-center justify-between w-full h-full opacity-70 absolute"
            style={{ backgroundColor: team?.theme?.color }}
          />
          <div
            className={`flex items-center justify-between w-full h-full absolute bg-dark-100 opacity-80`}
          />
          <img
            src={playerInfo?.photo}
            alt={playerInfo?.name}
            className={`top-0 ${
              type === "batting" ? "-left-2 absolute" : "left-2"
            } scale-125 h-full`}
          />

          <div
            className={`z-10 flex ${
              type === "batting"
                ? "flex-col text-right"
                : "gap-1 flex-row-reverse text-left flex-1 items-center justify-end"
            } h-full px-1 pt-1`}
          >
            <Typography
              variant="h"
              width="semibold"
              size="sm"
              className="leading-none text-gray-200"
            >
              {getStats()}
            </Typography>
            <Typography
              variant="p"
              width="bold"
              className="leading-none text-gray-200"
              size="xs"
            >
              {getShortName(playerInfo?.name)}
            </Typography>
            {isActive && (
              <Typography
                variant="h"
                size="lg"
                width="bold"
                className="text-primary-500 leading-none"
              >
                *
              </Typography>
            )}
          </div>
          {thisOver ? (
            <div className="flex-1 flex items-center gap-1 z-10">
              {thisOver?.map((ball: any, ind: number) => (
                <div
                  key={ind}
                  className="rounded-full w-4 border-1 border-primary-700 h-4 bg-primary-100 text-primary-700 font-semibold flex items-center justify-center text-xs"
                >
                  {ball.run}
                </div>
              ))}
            </div>
          ) : null}
        </>
      ) : (
        <div className="flex w-full h-full p-1 justify-center items-center">
          <div className="border border-dashed w-full h-full flex flex-col justify-center items-center text-center border-gray-600">
            {isOpponent ? (
              <Typography
                variant="span"
                size="xs"
                className="text-gray-400 leading-none"
              >
                Waiting
              </Typography>
            ) : (
              <>
                {/* <FaPlus className="text-gray-200 mb-1" /> */}
                <Typography
                  variant="span"
                  size="xs"
                  className="text-gray-200 leading-none"
                >
                  Select {role}
                </Typography>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
