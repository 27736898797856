import {
  getMatchResult,
  getTopRunScorers,
  getTopWicketTakers,
} from "../../utils/match";
import { CardBase } from "../common/cards/CardBase";
import Typography from "../common/data-display/Typography";
import { SummaryData } from "./SummaryData";

export const MatchSummary = ({ matchData }: any) => {
  console.log(
    "💡 | matchData:",
    getTopWicketTakers(
      matchData.squad.teamB.playingXI,
      matchData.innings[matchData.liveData.teamA.inning]?.bowlingOrder,
      3
    )
  );

  const teamA = {
    teamData: matchData?.teams?.teamA,
    topBatsmen: getTopRunScorers(
      matchData.squad.teamA.playingXI,
      matchData.innings[matchData.liveData.teamA.inning]?.battingOrder,
      3
    ),
    topBowlers: getTopWicketTakers(
      matchData.squad.teamB.playingXI,
      matchData.innings[matchData.liveData.teamA.inning]?.bowlingOrder,
      3
    ),
  };

  const teamB = {
    teamData: matchData?.teams?.teamB,
    topBatsmen: getTopRunScorers(
      matchData.squad.teamB.playingXI,
      matchData.innings[matchData.liveData.teamB.inning]?.battingOrder,
      3
    ),
    topBowlers: getTopWicketTakers(
      matchData.squad.teamA.playingXI,
      matchData.innings[matchData.liveData.teamB.inning]?.bowlingOrder,
      3
    ),
  };

  const firstInningsTeam =
    matchData.liveData.teamA.inning === "first" ? teamA : teamB;
  const secondInningsTeam =
    matchData.liveData.teamA.inning === "first" ? teamB : teamA;

  return (
    <div className="w-full text-center flex flex-col gap-4">
      <SummaryData
        inningsData={firstInningsTeam}
        inning={matchData.innings.first}
      />
      <SummaryData
        inningsData={secondInningsTeam}
        inning={matchData.innings.second}
      />

      <Typography
        variant="h"
        size="2xl"
        className="leading-none text-primary-500 font-semibold text-center"
      >
        {getMatchResult(matchData.result, matchData.teams)}
      </Typography>
    </div>
  );
};
