import { useState } from "react";

export const useTabState = <T extends string>(init: T) => {
  const [activeTab, setActiveTab] = useState<T>(init);
  const handleTabClick = (id: T) => setActiveTab(id);

  const handleNext = (tabs: T[] = []) => {
    const findCurrentIndex = tabs.findIndex((v) => v === activeTab);
    const nextActive = tabs[findCurrentIndex + 1];

    setActiveTab(nextActive);
  };
  return {
    activeTab,
    onTabClick: handleTabClick,
    onNext: handleNext,
  };
};
