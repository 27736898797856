import { getApi, postApi, putApi } from "./httpConfig";

// user
export const CreateUser = (data: any) => postApi("user/create", data);

// themes
export const GetAllThemes = (data: any) => postApi("theme/all", data);

// players
export const GetRandomCaptains = () => getApi("player/get-random-captains");

// dream team
export const CreateDreamTeam = (data: any) =>
  postApi("dream-team/create", data);
export const GetUserDreamTeam = (id: any) =>
  getApi(`dream-team/user-team/${id}`);
export const UpdateDreamTeam = (id: any, data: any) =>
  putApi(`dream-team/update-team/${id}`, data);

// quick match
export const CreateQuickMatch = (data: any) =>
  postApi("quick-match/start-quick-match", data);
export const GetQuickMatchData = (id: any) =>
  getApi(`quick-match/get-match-data/${id}`);
